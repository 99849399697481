import { useState } from "react";
// mui components
import { Box, Switch } from "@mui/material";
//axios
import EnhancedAxios from "../../api/EnhancedAxios";
// redux hooks
import { useSelector } from "react-redux";
// alerts
import { successAlert, errorAlert } from "../../utils/alerts";
import { getUserRole } from "../../utils/roles/getUserRole";

const ToggleStatus = ({ id, is_disabled, putApiUrl }) => {
    const [value, setValue] = useState(!is_disabled);
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const role = getUserRole(userData);
    const handleChange = (e) => {
        let formData = new FormData();
        formData.append("is_disabled", !e.target.checked);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/${role}${putApiUrl}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? "تم التعطيل"
                        : "تم التفعيل";
                successAlert(alertMsg);
                setValue(!response.data.is_disabled);
            })
            .catch((error) => {
                errorAlert(error);
            });
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
            }}
        >
            <Switch
                checked={value}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                // disabled={userData.id === id}
                size="small"
            />
        </Box>
    );
};

export default ToggleStatus;

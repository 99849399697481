import * as yup from "yup";

export const addOperatorSchema = yup.object().shape({
    name: yup
        .string()
        .min(4, "يجب ان يكون اسم المستخدم اكثر من 4 حروف")
        .required("من فضلك ادخل اسم المستخدم "),
    email: yup
        .string()
        .email("ادخل البريد الالكترونى الصحيح للسائق")
        .required("ادخل البريد الالكتروني"),
    is_disabled: yup.boolean(),
    password: yup
        .string()
        .min(8, " الرقم السري قصير جدا")
        .required("ادخل الرقم السري!"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري"),
});

import { MdOutlineLocalTaxi } from "react-icons/md";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";

export const operatorLinks = [
    {
        title: "ادارة السائقين",
        icon: <MdOutlineLocalTaxi />,
        path: "/drivers",
    },
    {
        title: "ادارة الطلبات  ",
        icon: <VscGitPullRequestNewChanges />,
        path: "/orders",
    },
];

import {
    IoHomeOutline,
    IoGiftOutline,
    IoCashOutline,
    IoCardOutline,
} from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import {
    MdOutlineLocalTaxi,
    MdOutlineLocationCity,
    MdOutlineNotificationsNone,
    MdSos,
} from "react-icons/md";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";
import { TbSettings, TbChartAreaLine } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { GrUserAdmin } from "react-icons/gr";

export const adminLinks = [
    {
        title: "الرئيسية",
        icon: <IoHomeOutline />,
        path: "/",
    },
    {
        title: "ادارة المدن",
        icon: <MdOutlineLocationCity />,
        path: "/cities",
    },
    {
        title: "ادارة المناطق",
        icon: <TbChartAreaLine />,
        path: "/areas",
    },
    {
        title: "ادارة المستخدمين",
        icon: <FiUsers />,
        path: "/users",
    },
    {
        title: "ادارة السائقين",
        icon: <MdOutlineLocalTaxi />,
        path: "/drivers",
    },
    {
        title: "ادارة مسؤلين العمليات",
        icon: <GrUserAdmin />,
        path: "/operators",
    },
    {
        title: "ادارة الطلبات  ",
        icon: <VscGitPullRequestNewChanges />,
        path: "/orders",
    },
    {
        title: "ادارة طلبات المساعدة ",
        icon: <MdSos />,
        path: "/help-requests",
    },
    {
        title: "ادارة فئات طلبات المساعدة ",
        icon: <BiCategory />,
        path: "/sos-categories",
    },
    {
        title: "ادارة الاشعارات ",
        icon: <MdOutlineNotificationsNone />,
        path: "/notification",
    },
    {
        title: "العروض و الهدايا",
        icon: <IoGiftOutline />,
        path: "/offers",
    },
    {
        title: "ادارة عمليات الشحن للمستخدمين",
        icon: <IoCashOutline />,
        path: "/transactions",
    },
    {
        title: "ادارة عمليات الشحن للمندوبين",
        icon: <IoCashOutline />,
        path: "/drivers-transactions",
    },
    {
        title: "ادارة الكروت مسبقة الدفع",
        icon: <IoCardOutline />,
        path: "/prepaid-cards",
    },
    {
        title: "الضبط",
        icon: <TbSettings />,
        path: "/settings",
    },
];

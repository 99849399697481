import { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// mui
import { Stack, TextField, FormControlLabel, Switch } from "@mui/material";
// components
import { SubmitButton, RingsLoader, PageTitle } from "../../../components";
import { ErrorsStack } from "../../../sections";
// scheme
import { editOperatorSchema } from "../../../schemes/operators/editOperatorSchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

const EditOperator = () => {
    const { operatorId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        if (values.password === "") {
            delete values["password"];
            delete values["password_confirmation"];
        }
        const formData = new FormData();
        for (let key in values) formData.append(key, values[key]);

        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/users/${operatorId}`, values)
            .then((res) => {
                successAlert("تم تعديل بيانات مسؤل العمليات");
                setSubmitting(false);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            name: "",
            email: "",
            is_disabled: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: editOperatorSchema,
        onSubmit,
    });
    // ---------- get_current_city_data ----------
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/users/${operatorId}`)
            .then((res) => {
                setValues((prev) => ({
                    ...prev,
                    name: res.data.name,
                    email: res.data.email,
                    is_disabled: res.data.is_disabled,
                }));
                setLoading(false);
            });
    }, []);
    // ---------- page_ui ----------
    if (loading) <RingsLoader />;
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <PageTitle title="تعديل مسؤل عمليات" />
                <Stack spacing={1} component="form" onSubmit={handleSubmit}>
                    <TextField
                        type="text"
                        name="name"
                        label="اسم المستخدم"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="email"
                        name="email"
                        label="البريد الالكترونى"
                        value={values.email ?? "لا يوجد"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="تعديل الرقم السري"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password_confirmation"
                        label="تاكيد تعديل الرقم السري"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!values.is_disabled}
                                onChange={(e) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        is_disabled: !e.target.checked,
                                    }))
                                }
                                name="is_disabled"
                            />
                        }
                        label="تفعيل"
                    />
                    <SubmitButton text="تعديل" disabled={isSubmitting} />
                    <ErrorsStack errors={errors} touched={touched} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditOperator;

import { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, TextField, Typography, Divider } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    UserLocation,
    TripPath,
} from "../../../components";
// alerts
import { errorAlert } from "../../../utils/alerts";
// moment
import moment from "moment";
import { translateToArabic } from "../../../utils/translate/englishToArabic";
import ChangeTripStatus from "../../../components/select/ChangeTripStatus";
import { getUserRole } from "../../../utils/roles/getUserRole";
const OrderDetails = () => {
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const { orderId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState({});
    const role = getUserRole(userData);
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${role}/trips/${orderId}`)
            .then((res) => {
                setOrderDetails(res.data);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="تفاصيل الطلب" />
            <Stack spacing="10px">
                <Typography variant="subtitle1">بيانات المستخدم</Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    width="100%"
                    maxWidth="800px"
                    paddingBottom="40px"
                    borderBottom="1px solid #333"
                    sx={{
                        "& .MuiTextField-root": {
                            flex: "1",
                            minWidth: "270px",
                        },
                    }}
                >
                    <TextField
                        name="username"
                        label="اسم المستخدم"
                        value={orderDetails.customer.name ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="userphone"
                        label="هاتف المستخدم"
                        value={orderDetails.customer.phone ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="usergender"
                        label="نوع المستخدم"
                        value={
                            orderDetails.customer_gender
                                ? translateToArabic(
                                      orderDetails.customer_gender
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Typography variant="subtitle1">بيانات السائق</Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    width="100%"
                    maxWidth="800px"
                    paddingBottom="40px"
                    borderBottom="1px solid #333"
                    sx={{
                        "& .MuiTextField-root": {
                            flex: "1",
                            minWidth: "270px",
                        },
                    }}
                >
                    <TextField
                        name="driver_name"
                        label="اسم السائق"
                        value={orderDetails.driver?.name ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_email"
                        label="البريد الالكترونى للسائق"
                        value={orderDetails.driver?.email ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_phone"
                        label="هاتف السائق"
                        value={orderDetails.driver?.phone ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="drivergender"
                        label="نوع السائق"
                        value={
                            orderDetails.driver_gender
                                ? translateToArabic(orderDetails.driver_gender)
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cartype"
                        label="نوع السيارة"
                        value={
                            orderDetails.car_type
                                ? translateToArabic(orderDetails.car_type)
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Typography variant="subtitle1">بيانات الرحلة</Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    width="100%"
                    maxWidth="800px"
                    sx={{
                        "& .MuiTextField-root": {
                            flex: "1",
                            minWidth: "270px",
                        },
                    }}
                >
                    {/* <TextField
                        name="status"
                        label="الحالة"
                        value={
                            orderDetails.status
                                ? translateToArabic(orderDetails.status)
                                : orderDetails.status
                        }
                        InputProps={{ readOnly: true }}
                    /> */}
                    <ChangeTripStatus
                        value={orderDetails.status}
                        orderId={orderDetails.id}
                    />
                    <TextField
                        name="extended_meters"
                        label="الامتار الاضافية"
                        value={orderDetails.extended_meters}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="total_cost"
                        label="اجمالى التكلفة"
                        value={orderDetails.total_cost}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="created_at"
                        label="تاريخ انشاء الطلب"
                        value={
                            orderDetails.created_at
                                ? moment(orderDetails.created_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_claimed_at"
                        label="وقت تحرك السائق"
                        value={
                            orderDetails.driver_claimed_at
                                ? moment(orderDetails.driver_claimed_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_arrived_at"
                        label="وقت وصول السائق"
                        value={
                            orderDetails.driver_arrived_at
                                ? moment(orderDetails.driver_arrived_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_cancelled_at"
                        label="وقت الغاء السائق للطلب"
                        value={
                            orderDetails.driver_cancelled_at
                                ? moment(
                                      orderDetails.driver_cancelled_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="customer_cancelled_at"
                        label="وقت الغاء الزبون للطلب"
                        value={
                            orderDetails.customer_cancelled_at
                                ? moment(
                                      orderDetails.customer_cancelled_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="customer_pickup_at"
                        label="وقت  ركوب العميل"
                        value={
                            orderDetails.customer_pickup_at
                                ? moment(
                                      orderDetails.customer_pickup_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_penalty"
                        label="التكلفة الاضافية"
                        value={orderDetails.cost_penalty ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                width="100%"
                maxWidth="800px"
            >
                <Box flex="1" minWidth="280px">
                    <Typography variant="subttitle2" marginBottom="10px">
                        مسار السائق
                    </Typography>{" "}
                    {orderDetails.status == "completed" ? (
                        <TripPath path={orderDetails.path.coordinates} />
                    ) : (
                        <p>رحلة غير مكتملة</p>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

export default OrderDetails;

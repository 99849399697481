// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, TextField, FormControlLabel, Switch } from "@mui/material";
// components
import { PageTitle, SubmitButton } from "../../../components";
import { ErrorsStack } from "../../../sections";
// schema
import { addOperatorSchema } from "../../../schemes/operators/addOperatorSchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

const AddOperator = () => {
    const userToken = useSelector((state) => state.userToken);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let key in values) formData.append(key, values[key]);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/users`, values)
            .then((res) => {
                resetForm();
                setSubmitting(false);
                successAlert(`تم اضافة مسؤل عمليات بنجاح ${values.name}`);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            roles: 7,
            name: "",
            email: "",
            is_disabled: true,
            password: "",
            password_confirmation: "",
        },
        validationSchema: addOperatorSchema,
        onSubmit,
    });
    // ---------- page_ui ----------
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <PageTitle title="اضافة مسؤل عمليات" />
                <Stack spacing={1} component="form" onSubmit={handleSubmit}>
                    <TextField
                        type="text"
                        name="name"
                        label="اسم المستخدم"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="email"
                        name="email"
                        label="البريد الالكترونى"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="الرقم السري"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password_confirmation"
                        label=" تأكيد الرقم السري"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!values.is_disabled}
                                onChange={(e) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        is_disabled: !e.target.checked,
                                    }))
                                }
                                name="is_disabled"
                            />
                        }
                        label="تفعيل"
                    />
                    <SubmitButton text="اضافة" disabled={isSubmitting} />
                    <ErrorsStack errors={errors} touched={touched} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddOperator;

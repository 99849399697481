import React from "react";
// mui
import { Stack, Typography } from "@mui/material";
// moment
import moment from "moment";
const OldMessageCard = ({ phone, content, requestedAt }) => {
    return (
        <Stack
            sx={{ boxShadow: 1, borderRadius: 2, padding: 2, width: "100%" }}
        >
            <Stack direction="row" alignItems="center" gap="8px">
                <Typography variant="h6"> مرسلة الى : </Typography>
                <Typography variant="subtitle1"> {phone}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="8px">
                <Typography variant="h6"> محتوى الرسالة : </Typography>
                <Typography variant="subtitle1"> {content}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="8px">
                <Typography variant="h6"> تاريخ الطلب : </Typography>
                <Typography variant="subtitle1">
                    {" "}
                    {requestedAt
                        ? moment(requestedAt).format("YYYY/MM/DD -- HH:mm")
                        : ""}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default OldMessageCard;
